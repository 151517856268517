<template>
  <div class="zq--table-header" :class="{ 'zq-hide': disabled }">
    <div class="d-flex align-items-center">
      <div class="zq--table-header-search">
        <!--  ROUNDS  DROPDOWN -->
        <ClSelect
          class="mb-2 text-right m-0 text-capitalize"
          :options="rounds"
          trackBy="value"
          labelTrack="label"
          :is-press-btn="false"
          :valueProp="rounds[0].value"
          v-if="rounds.length > 0"
          @checkValue="checkRound"
          requiredText=""
        />
        <!--  statuses  -->
        <ClSelect
          class="text-right m-0 mr-1 mb-2 d-xl-none text-capitalize"
          :class="{'showDropdown': statuses.length > 5}"
          v-if="statuses.length > 0"
          :value-prop="defaultStatus || statuses[0]"
          :options="statuses"
          :is-press-btn="false"
          requiredText=""
          @checkValue="checkStatus"
          :key="rerenderKey"
        />
        <div
          v-if="statuses.length"
          class="ml-0 mr-1 mb-2 text-capitalize d-xl-flex d-none align-items-center"
          :class="{'hideButtons': statuses.length > 5}"
        >
        <span v-if="theme === 'main' && statuses.length > 1">
          {{ $t('common.show') }}:
        </span>
          <CButton
            variant="outline"
            color="dark"
            class="zq--table-header-statuses-btn"
            @click="checkStatus({ value: status })"
            :class="{ 'active-status': status === currentStatus }"
            v-for="status in statuses"
            :key="status"
          >
            {{ status }}
          </CButton>
        </div>
        <!--  SEARCH SECTION -->
        <ClSearch
          class="mb-2"
          v-if="isSearchFieldVisible"
          :placeholder="placeholderSearch"
          @searchUpdate="searchUpdate"
        />
      </div>
      <ClSelect
        v-if="isContestsTable"
        class="mb-2 ml-2 text-right m-0 text-capitalize"
        :options="viewsData"
        trackBy="value"
        labelTrack="label"
        :is-press-btn="false"
        :valueProp="viewsData[0].value"
        @checkValue="checkView"
        requiredText=""
      />
    </div>

    <!--  ACTIONS SECTION -->
    <div class="mb-1 zq--header-actions">
      <!--   reload   -->
      <CButton
        size="sm"
        class="header-icon-buttons mr-3"
        @click="handleReload"
      >
        <CIcon name="cil-reload" />
      </CButton>
      <!-- FILTER ICON -->
      <ClFilter
        v-if="tableSettings && isFilterButtonVisible"
        :isFlag="isFlag"
        @toggleFilter="toggleFilter"
      />
      <!--     Cog   -->
      <CogFields
        v-if="tableSettings && isCogFieldsButtonVisible"
        :originalFields="originalFields"
        :fields="fields"
        @updateFields="toggleColumn"
        @clearFilter="clearFilter"
      />
      <!--  Upload CSV   -->
     <div class="button-group" v-if="!isMobile">
        <CButton
          v-if="currentPage === 'Members' || currentPage === 'Products' || currentPage === 'UnitOfMeasures'"
          variant="outline"
          @click="toggleModal((modal = !modal))"
          class="mr-2 zq--responsive-button"
          :disabled="isCsvUploadDisabled"
        >
          <i class="fa fa-upload mr-2"></i>
          {{ label }}
        </CButton>
        <CsvImportModal
          :config="csvModalConfig"
          :modalShow="modal"
          :descriptions="descriptions"
          @toggle-modal="closeModal"
          @load-csv="uploadCsv"
        />
      </div>
      <!--  Dropdown for multiselect actions    -->
      <MultiActionsDropdown
        v-if="tableSettings"
        :currentPage="currentPage"
        :isVisible="isVisible"
        :multiDeleteTitle="multiDeleteTitle"
        @deleteData="deleteData"
        @startAction="startAction"
        @cancelAction="cancelAction"
      />
      <!--   CREATE FOR LANGUAGES    -->
      <ClResponsiveButton
        v-if="currentPage === 'Languages'"
        :label="$t('buttons.addLanguage')"
        @handler="createItem"
      />
      <!--   CREATE FOR COLLABORATORS    -->
      <ClResponsiveButton
        v-else-if="currentPage === 'Collaborators'"
        :label="$t('buttons.add')"
        @handler="createItem"
      />
      <!--   CREATE FOR CONNECTIONS    -->
<!--      <ClResponsiveButton-->
<!--        v-else-if="currentPage === 'Connections'"-->
<!--        :label="$t('buttons.create')"-->
<!--        :disableButton="disableButton"-->
<!--        @handler="createConnection"-->
<!--      />-->
      <CDropdown
        v-else-if="currentPage === 'Connections'"
        inNav
        class="c-header-nav-items create-button-groups mr-2"
        placement="bottom-end"
        add-menu-classes="p-0"
      >
        <template #toggler>
          <ClResponsiveButton :label="$t('buttons.create')" />
        </template>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="createConnection('sqsConnection')"
          >
            SQS Connection
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="createConnection('rabbitMqConnection')"
          >
            RabbitMQ Connection
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="createConnection('kafkaConnection')"
          >
            Kafka Connection
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="createConnection('scheduledConnection')"
          >
            Scheduled Connection
          </CButton>
        </CDropdownItem>
      </CDropdown>

      <!--   CREATE FOR MESSAGES    -->
<!--      <CDropdown-->
<!--        v-else-if="currentPage === 'Inbox'"-->
<!--        inNav-->
<!--        class="c-header-nav-items create-button-groups mr-2"-->
<!--        placement="bottom-end"-->
<!--        add-menu-classes="p-0"-->
<!--      >-->
<!--        <template #toggler>-->
<!--          <ClResponsiveButton :label="$t('buttons.create')" />-->
<!--        </template>-->
<!--        <CDropdownItem class="p-0 actions-dropdown-item">-->
<!--          <CButton-->
<!--            pill-->
<!--            color="dark"-->
<!--            class="action-custom-button"-->
<!--            variant="ghost"-->
<!--            @click="createMessage('CreateNotification')"-->
<!--          >-->
<!--            Notification-->
<!--          </CButton>-->
<!--        </CDropdownItem>-->
<!--        <CDropdownItem class="p-0 actions-dropdown-item">-->
<!--          <CButton-->
<!--            pill-->
<!--            color="dark"-->
<!--            class="action-custom-button"-->
<!--            variant="ghost"-->
<!--            @click="createMessage('member')"-->
<!--          >-->
<!--            Member-->
<!--          </CButton>-->
<!--        </CDropdownItem>-->
<!--        <CDropdownItem class="p-0 actions-dropdown-item">-->
<!--          <CButton-->
<!--              pill-->
<!--              color="dark"-->
<!--              class="action-custom-button"-->
<!--              variant="ghost"-->
<!--              @click="createMessage('account')"-->
<!--          >-->
<!--            Account-->
<!--          </CButton>-->
<!--        </CDropdownItem>-->
<!--      </CDropdown>-->
      <!--   CREATE FOR CONTEST    -->
      <div v-else-if="currentPage === 'PreviewCompetition'" class="d-flex">
<!--        <CButton
          color="dark"
          class="mr-3 common-buttons zq&#45;&#45;custom-button"
          variant="outline"
          @click="goToCompetitionLeaderboard"
        >
          {{ $t('buttons.leaderboard') }}
        </CButton>-->
        <ClResponsiveButton
          :label="$t('buttons.createContest')"
          @handler="createItem"
        />
      </div>
      <!--  CREATE  FOR Competitions     -->
      <CDropdown
        v-else-if="currentPage === 'Competitions'"
        inNav
        class="
          c-header-nav-items
          create-button-groups
          zq--actions-dropdown--xl
        "
        placement="bottom-end"
        add-menu-classes="p-0"
      >
        <template #toggler>
          <ClResponsiveButton
            :label="$t('buttons.create')"
          />
        </template>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="createQuickCompetition"
          >
            {{ $t('buttons.quick') }}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            variant="ghost"
            @click="createAdvancedCompetition"
          >
            {{ $t('buttons.advanced') }}
          </CButton>
        </CDropdownItem>
      </CDropdown>
      <!--   Default   -->
      <ClResponsiveButton
        v-else-if="isCreateButtonVisible"
        :label="$t('buttons.create')"
        :disableButton="disableButton"
        @handler="createItem"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { startCase } from 'lodash';
import CogFields from '@/components/header/CogFields';
import ClFilter from '@/components/header/ClFilter';
import CsvImportModal from '@/components/CsvImportModal';
import MultiActionsDropdown from './components/MultiActionsDropdown';
import ClSelect from '@/shared/components/formComponents/ClSelect';
import ClSearch from '@/shared/components/formComponents/ClSearch';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';

export default {
  name: 'TableHeader',
  props: {
    tableSettings: {
      type: Boolean,
      default: true,
    },
    multiDeleteTitle: {
      type: String,
      default: 'delete',
    },
    disabled: Boolean,
    disableButton: Boolean,
    isCreateButtonVisible: {
      type: Boolean,
      default: true,
    },
    isCogFieldsButtonVisible: {
      type: Boolean,
      default: true,
    },
    isFilterButtonVisible: {
      type: Boolean,
      default: true,
    },
    isLogEvents: {
      type: Boolean,
      default: false,
    },
    originalFields: Array,
    fields: Array,
    statuses: Array,
    defaultStatus: String,
    title: String,
    csvModalConfig: Object,
    descriptions: Object,
    isReloadVisible: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Upload From CSV',
    },
    isVisible: Number,
    isSearchFieldVisible: {
      type: Boolean,
      default: true,
    },
    rounds: {
      type: Array,
      default() {
        return [];
      },
    },
    isContestsTableView: {
      type: Boolean,
      default: false
    },
    handleContestsView: Function,
    isContestsTable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CsvImportModal,
    CogFields,
    MultiActionsDropdown,
    ClSelect,
    ClSearch,
    ClResponsiveButton,
    ClFilter,
  },
  data() {
    return {
      currentStatus: 'All',
      options: ['All Status', 'Ready', 'Cancelled'],
      isFlag: false,
      modal: false,
      currentPage: '',
      isCsvUploadDisabled: false,
      csvUploadDisabledPages: [],
      viewsData: [
        { value: 'tableView', label: 'Table view' },
        { value: 'bracketView', label: 'Bracket view' }
      ],
      isMobile: window.innerWidth <= 991,
      rerenderKey: 0
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    placeholderSearch() {
      return this.$props.isLogEvents ? 'tableHeader.searchById' : 'tableHeader.search';
    },
  },
  watch: {
    defaultStatus(val) {
      this.currentStatus = val;
      this.rerenderKey++
    }
  },
  created() {
    this.currentPage = this.$router.currentRoute.name;
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.checkCsvUploadAvailability();
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 991
    },
    checkCsvUploadAvailability() {
      if (this.csvUploadDisabledPages.includes(this.currentPage)) {
        this.isCsvUploadDisabled = true;
      }
    },
    toggleModal(val) {
      this.modal = val;
    },
    closeModal() {
      this.modal = false;
    },
    toggleColumn(e) {
      this.$emit('updateFields', e);
    },
    createItem() {
      this.$emit('createItem');
    },
    /*goToCompetitionLeaderboard() {
      this.$emit('goToCompetitionLeaderboard');
    },*/
    toggleFilter() {
      this.isFlag = !this.isFlag;
      this.$emit('toggleFilter', this.isFlag);
    },
    checkStatus({ value }) {
      this.currentStatus = value;
      this.$emit('checkStatus', value);
    },
    clearFilter() {
      this.$emit('clearFilter');
    },
    deleteData() {
      this.$emit('deleteData');
    },
    searchUpdate(val) {
      this.$emit('searchData', val);
    },
    caseTransform(val) {
      return startCase(val);
    },
    uploadCsv(file) {
      this.$emit('uploadFromCSV', file);
    },
    handleReload() {
      this.$emit('reload');
    },
    createConnection(val) {
      // this.$emit('createConnection', val);

      let routerName;
      switch (val) {
        case 'sqsConnection':
          routerName = 'CreateSqsConnection';
          break;
        case 'rabbitMqConnection':
          routerName = 'CreateRabbitMqConnection';
          break;
        case 'kafkaConnection':
          routerName = 'CreateKafkaConnection';
          break;
        case 'scheduledConnection':
          routerName = 'CreateScheduledConnection';
          break;
      }

      this.$router.push({
        name: `${routerName}`
      })
    },
    createMessage(val) {
      this.$emit('createMessage', val);
    },
    createAdvancedCompetition() {
      this.$emit('createAdvancedCompetition');
      this.$router.push({
        name: 'AdvancedCompetition'
      })
    },
    createQuickCompetition() {
      this.$emit('createQuickCompetition');
      this.$router.push({
        name: 'SimpleCompetition'
      })
    },
    startAction() {
      this.$emit('startAction');
    },
    cancelAction() {
      this.$emit('cancelAction');
    },
    checkRound({ value }) {
      this.$emit('checkRound', value);
    },
    checkView({value}) {
      this.handleContestsView(value);
    }
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/mixins';

.button-group {
  position: relative;

  .upload-csv {
    height: 100%;
  }

  .btn-upload-csv {
    position: absolute;
    top: 0;
    opacity: 0;
  }
}

.active-status {
  color: #fff;
  background-color: #636f83;
}

.action-button-groups {
  list-style: none !important;
}

.custom-dropbox {
  .dropdown-menu {
    min-width: 80px;
    width: 80px;
  }
}

.dropdown-menu.show {
  transform: translate(-52px, 36px);
}

.create-button-groups {
  .dropdown-menu {
    transform: translate(2px, 38px);
    min-width: 93px;
    width: max-content;
  }
}

.zq--table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media-breakpoint-down('576px') {
    flex-direction: column;
  }
  .zq--table-header-search {
    display: flex;
    align-items: center;
    //flex: 2;
    flex-wrap: wrap;
    @include media-breakpoint-down('576px') {
      //width: 100%;
      justify-content: space-between;
    }
    .showDropdown {
      @media screen and (max-width: 1720px) {
        display: block !important;
      }
    }
    .hideButtons {
      @media screen and (max-width: 1720px) {
        display: none !important;
      }
    }
  }
}

.zq--header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //flex: 1;
  @include media-breakpoint-down('576px') {
    flex-wrap: wrap;
    //width: 100%;
  }
}

.zq--table-header-statuses-btn {
  margin-right: 4px;
}
</style>
