export default function (element) {
  return {
    updated() {
      // IE ON LOAD
      if (this.$refs[element]) {
        this.checkTableScrollbar(this.$refs[element].$el.children[0]);
      }
    },
    mounted() {
      if (this.$refs[element]) {
        this.resizeObserver = this.resizeTable(this.$refs[element]);
      }
    },
    methods: {
      resizeTable(tableRef) {
        const tableWrapper = tableRef.$el.children[0];
        const resizeObserver = new ResizeObserver(() => {
          this.checkTableScrollbar(tableWrapper);
        });
        resizeObserver.observe(tableWrapper);
        return resizeObserver
      },
      checkTableScrollbar(tableWrapper) {
        const tableEl = tableWrapper.children[0];
        const tableScroll = tableWrapper.offsetWidth < tableEl.scrollWidth;
        if (this.isTableScrollbar !== tableScroll) {
          this.isTableScrollbar = tableWrapper.offsetWidth < tableEl.scrollWidth;
        }
      }
    },
    destroyed() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
  }
}