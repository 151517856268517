<template>
  <CDropdown
      inNav
      class="c-header-nav-items action-button-groups mr-2 custom-dropbox"
      add-menu-classes="p-0"
      v-if="isVisible > 0"
  >
    <template #toggler>
      <CButton
          class="zq--custom-button"
          variant="outline"
          :color="colorBtn"
      >{{ $t('buttons.actions') }}
        <i
            v-if="theme === 'main'"
            class="fa fa-angle-down font-weight-bold"></i>
        <i v-else class="fa fa-caret-down"></i>
      </CButton>
    </template>
<!--    <CDropdownItem-->
<!--        v-if="currentPage === 'Competitions' || currentPage === 'PreviewCompetition'"-->
<!--        class="p-0 actions-dropdown-item">-->
<!--      <CButton-->
<!--          pill-->
<!--          :color="colorBtn"-->
<!--          class="action-custom-button"-->
<!--          variant="ghost"-->
<!--          @click="startAction"-->
<!--      >-->
<!--        {{ $t('buttons.start') }}-->
<!--      </CButton>-->
<!--    </CDropdownItem>-->
    <CDropdownItem
        v-if="currentPage === 'Competitions' || currentPage === 'PreviewCompetition'"
        class="p-0 actions-dropdown-item">

      <CButton
          pill
          :color="colorBtn"
          class="action-custom-button"
          variant="ghost"
          @click="cancelAction"
      >
        {{ $t('buttons.cancel') }}
      </CButton>
    </CDropdownItem>
<!--    <CDropdownItem-->
<!--        v-if="currentPage === 'Inbox' || currentPage === 'Deleted' || currentPage === 'Sent' || currentPage === 'Draft'"-->
<!--        class="p-0 w-100" style="width: 100px">-->

<!--      <CButton-->
<!--          pill-->
<!--          color="dark"-->
<!--          class="add-temp-button"-->
<!--          variant="ghost"-->
<!--      >-->
<!--        Add To Template-->
<!--      </CButton>-->
<!--    </CDropdownItem>-->
    <CDropdownItem v-else-if="currentPage !== 'Deleted'"
        class="p-0 actions-dropdown-item">
      <CButton
          pill
          :color="colorBtn"
          class="action-custom-button"
          variant="ghost"
          @click="deleteData"
      >
        {{ $t(multiActionTitle) }}
      </CButton>
    </CDropdownItem>


  </CDropdown>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    isVisible: Number,
    multiDeleteTitle: {
      type: String,
      default: 'delete'
    },
    currentPage: String
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    multiActionTitle() {
      if (this.multiDeleteTitle === 'archive') {
        return 'buttons.Archived'
      }
      return 'buttons.delete'
    },
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  methods: {
    deleteData() {
      this.$emit('deleteData');
    },
    startAction() {
      this.$emit('startAction');
    },
    cancelAction() {
      this.$emit('cancelAction');
    },
  }
}
</script>
